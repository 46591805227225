// Styling for the coliving grid
.coliving {
  .coliving-item {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: $secondary;
    border-radius: $border-radius;
    display: block;
    max-width: 25rem;
    overflow: hidden;
    padding-top: 56.25%;
    position: relative;

    .coliving-item-details {
      height: 0px;
      transition: $transition-base;
      opacity: 1;
    }

    &:hover .coliving-item-caption {
      height: 100%;
      .coliving-item-details {
        opacity: 1;
        height: auto;
      }
    }

    .coliving-item-caption {
      background-color: fade-out($primary, 0.1);
      border-radius: $border-radius-sm;
      height: 20%;
      left: 0;
      opacity: 1;
      position: absolute;
      bottom: 0;
      transition: $transition-base;

      .coliving-item-caption-content {
        font-size: 1.5rem;
      }
    }
  }
}

.coliving-images {
  grid-template-columns: 60% 20% 20%;
  border-radius: $border-radius;
  overflow: hidden;
  display: grid;

  .coliving-images-image {
    padding-top: 56.25%;
    width: 100%;
    height: 0px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: $secondary;
  }

  .one {
    grid-row: 1 / 3;
    order: 1;
  }

  .two {
    order: 2;
    grid-column: 2 / 4;
  }

  .three {
    order: 3;
  }

  .four {
    grid-column: 3 / 4;
    order: 4;
  }
}
