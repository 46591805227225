// Custom extra large button size
.btn-xl {
  padding: 1rem 1.75rem;
  font-size: 1.25rem;
}

// Custom social buttons
.btn-social {
  border-radius: 100%;
  display: inline-flex;
  width: 3.25rem;
  height: 3.25rem;
  font-size: 1.25rem;
  justify-content: center;
  align-items: center;
}

// Scroll to top button
.scroll-to-top {
  z-index: 1042;
  right: 1rem;
  bottom: 1rem;
  display: none;

  a {
    width: 3.5rem;
    height: 3.5rem;
    background-color: fade-out($gray-900, 0.5);
    line-height: 3.1rem;
  }
}
