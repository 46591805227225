/*
.img-wrap {
  position: relative;
  .img-content {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    > img {
      max-width: 100%;
      max-height: 100%;
      width: 100%;
    }
  }
  &.ratio-4-3 {
    padding-top: 75%;
  }
  &.ratio-16-9 {
    padding-top: 56.25%;
  }
  &.ratio-3-2 {
    padding-top: 66.66%;
  }
  &.ratio-8-5 {
    padding-top: 62.5%;
  }
}
*/
